<template>
  <v-row>
    <v-dialog v-model="dialogBadge" scrollable persistent max-width="400px">
      <v-card v-if="badges">
        <v-toolbar dense flat>
          Lencana {{ nama }}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('false')"
            ><v-icon>mdi-close keluar</v-icon></v-btn
          >
        </v-toolbar>
        <div class="d-flex flex-wrap justify-center pa-3">
          <div v-for="(item, idx) in badges" :key="idx" class="ma-2">
            <img :src="`${env}/badges/${item}`" height="100px" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "badgedetail",
  props: ["dialogBadge", "badges", "nama"],
  data() {
    return {
      close: false,
      counting: null,
      env: process.env.VUE_APP_GRAPHQL
    };
  },
  methods: {}
};
</script>

<style></style>
