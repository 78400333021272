<template>
  <div class="temp-leader">
    <section class="head indigo">
      Leaderboard Kelompok
    </section>
    <v-simple-table v-if="leaderboardX">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Rank
            </th>
            <th class="text-left">
              Kelompok
            </th>
            <th class="text-left">
              Poin
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in leaderboardX.data" :key="idx">
            <td>
              <b :class="idx == 0 ? 'orange--text' : ''">{{ idx + 1 }}</b>
            </td>
            <td>{{ item.nama_grup }}</td>
            <td>
              <v-icon color="yellow accent-4"
                >mdi-currency-usd-circle-outline</v-icon
              >{{ item.poin }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LeaderboardX",
  computed: {
    ...mapState("leaderboard", ["leaderboardX"])
  },
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.temp-leader {
  width: 100%;
}
.head {
  padding: 10px;
  border-radius: 8px 8px 0 0;
  color: white;
}
</style>
