<template>
  <div class="container-general">
    <div class="temp-general">
      <section class="banner d-flex">
        <h1 class="white--text">Ringkasan</h1>
      </section>
      <section class="d-flex pa-3 justify-space-between">
        <v-col>
          <v-card
            class="pa-5 blue darken-1 d-flex justify-space-around align-center"
            height="200px"
            :elevation="3"
          >
            <v-icon dark x-large> mdi-account-child </v-icon>
            <h1 class="white--text">Total Widyaiswara</h1>
            <h1 class="white--text">{{ teacher }}</h1>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="card-ringkasan-mentor pa-5 d-flex justify-space-around align-center"
            height="200px"
            :elevation="3"
          >
            <v-icon dark x-large> mdi-account-child </v-icon>
            <h1 class="white--text">Total Coach</h1>
            <h1 class="white--text">{{ mentor }}</h1>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="card-ringkasan-student pa-5 d-flex justify-space-around align-center"
            height="200px"
            :elevation="3"
          >
            <v-icon dark x-large> mdi-account-child </v-icon>
            <h1 class="white--text">Total Peserta</h1>
            <h1 class="white--text">{{ student }}</h1>
          </v-card>
        </v-col>
      </section>
      <v-row>
        <v-col cols="12" md="3">
          <v-card
            class="card-ringkasan-modul pa-5 d-flex justify-space-around align-center"
            height="200px"
            :elevation="3"
          >
            <v-icon dark x-large> mdi-account-child </v-icon>
            <h1 class="white--text">Total Modul</h1>
            <h1 class="white--text">{{ modul }}</h1>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card
            class="pa-5 purple accent-4 d-flex justify-space-around align-center"
            :elevation="3"
            height="200px"
          >
            <v-icon dark x-large> mdi-account </v-icon>
            <h1 class="white--text">Total Pelatihan</h1>
            <h1 class="white--text">{{ event }}</h1>
          </v-card>
        </v-col>
        <v-col cols="12" md="5">
          <div class="temp-list">
          <div
            class="post-status-temp mb-4"
            :class="
              feed.judulFeedback == 'Bug'
                ? 'item _bug'
                : feed.judulFeedback == 'Komentar'
                ? 'item _comment'
                : 'item _etc'
            "
            v-for="(feed, q) in listFeedback"
            :key="q"
          >
            <div class="pa-2">
              <section class="feed mx-3">
              <div class="heads"></div>
              <v-card flat>
                <v-toolbar flat height="50px">
                  <v-avatar color="indigo" size="40" class="mr-2">
                    <span class="white--text font-weight-bold">{{
                      feed.namaUser[0].toUpperCase()
                    }}</span>
                  </v-avatar>
                  <h4>{{ feed.namaUser }}</h4>
                </v-toolbar>
                <section class="px-3 pb-3">
                  <h3>Tipe feeds : {{ feed.judulFeedback }}</h3>
                  <p v-html="feed.isiFeedback"></p>
                  <footer>
                    <v-icon>mdi-clock</v-icon>
                    <small>{{ moment(feed.createdAt).format("HH.ss") }}</small>
                    | <v-icon>mdi-calendar</v-icon>
                    <small>{{
                      moment(feed.createdAt).format("DD-MM-YYYY")
                    }}</small>
                  </footer>
                </section>
              </v-card>
            </section>
            </div>
          </div>
          </div>
          <router-link to="/list-feedback" style="text-decoration: none">
            <v-btn color="indigo" dark>
              <!-- <v-icon dark small> mdi-pencil </v-icon> -->
              Lihat Semua
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { COUNT_EVENT } from "../../graphql/Event";
import { COUNT_MODULE } from "../../graphql/Module";
import { COUNT_TEACHER } from "../../graphql/Teacher";
import { COUNT_STUDENT } from "../../graphql/Student";
import { COUNT_MENTOR } from "../../graphql/Mentor";
import { mapState } from "vuex";
import moment from "moment";

export default {
  apollo: {
    allPelatihan: {
      query: COUNT_EVENT,
      result(data) {
        this.event = data.data.allPelatihan.paginatorInfo.total;
      },
      variables() {
        return { page: 1, first: 10 };
      },
      error(err) {
        console.log(err);
      }
    },
    allModul: {
      query: COUNT_MODULE,
      result(data) {
        this.modul = data.data.allModul.paginatorInfo.total;
      },
      variables() {
        return { page: 1, first: 10 };
      },
      error(err) {
        console.log(err);
      }
    },
    scopeTeacher: {
      query: COUNT_TEACHER,
      variables() {
        return { page: 1, first: 10 };
      },
      result(data) {
        this.teacher = data.data.scopeTeacher.paginatorInfo.total;
      },
      error(err) {
        console.log(err);
      }
    },
    scopeStudent: {
      query: COUNT_STUDENT,
      variables() {
        return { page: 1, first: 10 };
      },
      result(data) {
        this.student = data.data.scopeStudent.paginatorInfo.total;
      },
      error(err) {
        console.log(err);
      }
    },
    scopeMentor: {
      query: COUNT_MENTOR,
      variables() {
        return { page: 1, first: 10 };
      },
      result(data) {
        this.mentor = data.data.scopeMentor.paginatorInfo.total;
      },
      error(err) {
        console.log(err);
      }
    }
  },
  computed: {
    ...mapState({
      listFeedback: state => state.feedback.listFeedback,
      feedbackById: state => state.feedback.feedbackbyid,
      lastFeed: state => state.feedback.lastFeed,
    })
  },
  mounted() {
    this.$store.dispatch("feedback/listFeedback", { page: this.page, per_page: this.limit });
  },
  name: "General",
  data() {
    return {
      event: null,
      teacher: null,
      modul: null,
      student: null,
      mentor: null,
      moment: moment,
      page: 1,
      limit: 5,
    };
  },
  components: {}
};
</script>

<style scoped>
nav {
  padding: 20px 10px;
}
.container-general {
  width: 100%;
  background: #f4f6f9;
  padding: 10px;
  min-height: 90vh;
}
.banner {
  background: #1a237e;
  border-radius: 8px;
  padding: 10px;
}
.tl-general {
  width: 100%;
  padding: 10px;
}
.card-ringkasan-modul {
  background-color: #fe8525;
  border-color: #fe8525;
}
.card-ringkasan-student {
  background-color: #ffc209;
  border-color: #ffc209;
}
.card-ringkasan-mentor {
  background-color: #ff5376;
  border-color: #ff5376;
}
.post-status-temp {
  display: flex;
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
}
.dva {
  object-fit: cover;
}
.post-input {
  border: none;
  background: #f4f6f9;
  width: 80%;
  border-radius: 8px;
  outline: none;
  padding: 10px;
}
.temp-tl {
  padding: 0 20px 0 20px;
}
</style>
