<template>
  <div>
    <DashboardAdmin v-if="role_id == 1" />
    <DashboardTeacher v-else-if="role_id == 2" />
    <DashboardMentor v-else-if="role_id == 3" />
    <DashboardStudent v-else-if="role_id == 4" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardAdmin from "../../components/Admin/dashboardAdmin";
import DashboardTeacher from "../../components/Teacher/dashboardTeacher";
import DashboardMentor from "../Mentor/dashboardMentor";
import DashboardStudent from "../../components/Student/DashboardStudent";

export default {
  name: "dashboard",
  components: {
    DashboardAdmin,
    DashboardTeacher,
    DashboardMentor,
    DashboardStudent
  },
  computed: {
    ...mapState(["role_id", "token"])
  }
};
</script>

<style></style>
