<template>
  <div class="container-general">
    <v-btn class="indigo mb-1" dark @click="helpDashboard = true"
      ><v-icon class="mr-1">mdi-help-circle-outline</v-icon> Bantuan</v-btn
    >
    <TutorDashboard v-bind:helpDashboard="helpDashboard" @close="close" />
    <div class="temp-general">
      <section class="indigo banner d-flex justify-space-between">
        <section class="d-flex">
          <img src="../../assets/img/time.svg" height="100px" />
          <div class="white--text">
            <h1>Hari ke - {{ countDay }} pelatihan</h1>
            <p>Fase {{ phase }}</p>
          </div>
        </section>
        <section class="white--text">
          <div v-if="phase == 'Individu'">
            <h1>Batas Waktu Fase Individu</h1>
            <p>{{ moment(end_evt).format("DD MMMM YYYY") }}</p>
          </div>
          <div v-if="phase == 'Kelompok'">
            <h1>Batas Waktu Fase Kelompok</h1>
            <p>{{ moment(end_evt).format("DD MMMM YYYY") }}</p>
          </div>
        </section>
      </section>
      <v-row>
        <v-col cols="12" md="7">
          <LeaderboardUser></LeaderboardUser>
        </v-col>
        <v-col cols="12" md="5">
          <LeaderboardTeam></LeaderboardTeam>
        </v-col>
      </v-row>
      <section class="tl-general">
        <section class="rounded indigo pa-2 my-4">
          <h2 class="white--text">Feeds</h2>
        </section>
        <!-- <div class="post-status-temp d-flex">
          <v-textarea
            solo
            label="Bagikan sesuatu kepada yang lain"
            dense
            row-height="7"
            :auto-grow="true"
            hide-details="auto"
            flat
          ></v-textarea>
          <v-btn dark fab small color="indigo">
            <v-icon dark small> mdi-send small </v-icon>
          </v-btn>
        </div> -->
        <div class="temp-tl" v-if="broadcast">
          <div
            class="post-status-temp mt-4"
            v-for="msg in broadcast.data"
            :key="msg.id"
          >
            <section class="d-flex align-center">
              <!-- <v-avatar size="40" color="indigo">
                <span class="white--text font-weight-bold"></span>
              </v-avatar> -->
              <h3 class="mx-3">PENGUMUMAN</h3>
            </section>
            <section class="my-2 d-flex">
              <div style="width: 50px"></div>
              <v-card class="pa-3" style="width: 90%">
                <span>{{ msg.isi }}</span>
              </v-card>
            </section>
            <footer class="d-flex">
              <div style="width: 50px"></div>
              <div>
                <v-icon color="grey" small>mdi-clock-outline</v-icon>
                <small class="grey--text ml-2">{{ moment(msg.created_at).format("HH:mm") }} |
                  {{ moment(msg.created_at).format("DD MMMM YYYY") }}</small>
              </div>
            </footer>
          </div>
        </div>

        <div class="temp-tl" v-if="feeds">
          <div class="post-status-temp mt-4" v-for="(log, i) in feeds" :key="i">
            <section class="d-flex align-center">
              <v-avatar size="40" color="indigo">
                <span class="white--text font-weight-bold">{{
                  log.nama_lengkap[0].toUpperCase()
                }}</span>
              </v-avatar>
              <h3 class="mx-3">{{ log.nama_lengkap }}</h3>
            </section>
            <section class="my-2 d-flex">
              <div style="width: 50px"></div>
              <v-card class="pa-3" style="width: 90%">
                <span v-html="log.keterangan"></span>
              </v-card>
            </section>
            <footer class="d-flex">
              <div style="width: 50px"></div>
              <div>
                <v-icon color="grey" small>mdi-clock-outline</v-icon>
                <small class="grey--text ml-2"
                  >{{ moment(log.created_at).format("HH:mm") }} |
                  {{ moment(log.created_at).format("DD MMMM YYYY") }}</small
                >
              </div>
            </footer>
          </div>
          <div v-if="feeds" v-observe-visibility="handleScroll"></div>
        </div>
        <div class="d-flex justify-center" v-if="!feeds">
          <v-progress-circular
            indeterminate
            size="30"
            color="indigo"
            class="mr-2 ml-2"
          ></v-progress-circular>
          memuat
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import LeaderboardTeam from "./Leaderboard/LeaderboardTeam";
import LeaderboardUser from "./Leaderboard/LeaderboardUser";
import { mapState } from "vuex";
import moment from "moment";
import TutorDashboard from "./tutorial/tutorDashboard.vue";
// import Swal from "sweetalert2";

export default {
  name: "General",
  computed: {
    // ...mapState(["countDay", "feeds", "lastLog", "end_evt", "start_evt"]),
    ...mapState({
      countDay: (state) => state.countDay,
      feeds: (state) => state.feeds,
      lastLog: (state) => state.lastLog,
      end_evt: (state) => state.end_evt,
      start_evt: (state) => state.start_evt,
      broadcast: (state) => state.broadcast.broadcast,
    }),
  },
  components: {
    LeaderboardTeam,
    LeaderboardUser,
    TutorDashboard,
  },
  data() {
    return {
      moment: moment,
      perpage: 5,
      page: 1,
      phase: localStorage.getItem("fase"),
      helpDashboard: false,
    };
  },
  mounted() {
    this.$store.dispatch("theFeeds", {
      per_page: this.perpage,
      page: this.page,
    }); //get feeds
    this.getBroadcast();
    this.$store.dispatch("leaderboard/leadX", { page: 1, per_page: 5 }); //get leaderboard group
    this.$store.dispatch("leaderboard/leadY", { page: 1, per_page: 5 }); //get leaderboard individu

    // let today = moment().startOf("day");
    // let countDown = today.diff(this.end_evt, "days");
    // countDown = countDown.toString().replace(/-/, ' ');
    // console.log(countDown);
    // if(parseInt(countDown) < 3) {
    //   Swal.fire({
    //     title: `fase ${this.phase} berakhir ${countDown} hari lagi`,
    //     icon: "warning",
    //     confirmButtonColor: "#3085d6",
    //   })
    // }
  },
  methods: {
    getBroadcast() {
      this.$store.dispatch("broadcast/getBroadcast");
    },
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.page <= this.lastLog) {
          this.page++;
          console.log(this.page);
          this.$store.dispatch("theFeeds", {
            per_page: this.perpage,
            page: this.page,
          });
        }
      }
    },
    close() {
      this.helpDashboard = false;
    },
  },
};
</script>

<style scoped>
nav {
  padding: 20px 10px;
}
.container-general {
  width: 100%;
  background: #f4f6f9;
  padding: 10px;
  min-height: 90vh;
}
.banner {
  /* background: #1A237E; */
  border-radius: 8px;
  padding: 10px;
}
.tl-general {
  width: 100%;
}
.post-status-temp {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
}
.dva {
  object-fit: cover;
}
.post-input {
  border: none;
  background: #f4f6f9;
  width: 80%;
  border-radius: 8px;
  outline: none;
  padding: 10px;
}
.temp-tl {
  padding: 0 20px 0 20px;
}
.leader-board {
  width: 100%;
}
</style>
