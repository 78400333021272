<template>
  <div class="temp-leader">
    <section class="head indigo">Leaderboard Individu</section>
    <v-simple-table v-if="leaderboardY">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Rank</th>
            <th class="text-left">Nama</th>
            <th class="text-left">Kelompok</th>
            <th class="text-left">Poin</th>
            <th class="text-left">Badge</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, idx) in leaderboardY"
            :key="idx"
            :class="item.tugas_individu ? 'gold' : ''"
          >
            <td>
              <b :class="idx == 0 ? 'orange--text' : ''">{{ idx + 1 }}</b>
            </td>
            <td>{{ item.nama_lengkap }}</td>
            <td>{{ item.nama_grup }}</td>
            <td>
              <v-icon color="yellow accent-4"
                >mdi-currency-usd-circle-outline</v-icon
              >{{ item.poin }}
            </td>
            <td>
              <div class="py-3">
                <v-tooltip top max-width="150px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.badge"
                      v-bind="attrs"
                      v-on="on"
                      color="blue"
                      icon
                      @click="openBadge(item)"
                    >
                      <img
                        :src="`${env}/badges/${
                          item.badge[item.badge.length - 1]
                        }`"
                        height="50px"
                      />
                    </v-btn>
                  </template>
                  <span class="text-center"
                    >Klik untuk melihat semua badge</span
                  >
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div>
      <DialogBadge
        v-bind:badges="badges"
        v-bind:dialogBadge="dialogBadge"
        v-bind:nama="nama"
        @false="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DialogBadge from "../../_base/dialogBadge.vue";

export default {
  name: "LeaderboardY",
  components: {
    DialogBadge,
  },
  computed: {
    ...mapState("leaderboard", ["leaderboardY"]),
    // rank() {
    //   let newArr = [];
    //   if (this.leaderboardY) {
    //     newArr = [...this.leaderboardY.data];
    //     newArr.map((el) => {
    //       if (el.badge) {
    //         return (el.badge = el.badge.split(","));
    //       }
    //     });
    //   }
    //   return newArr;
    // },
  },
  data() {
    return {
      msg: "hallo",
      env: process.env.VUE_APP_GRAPHQL,
      dialogBadge: false,
      badges: null,
      nama: null,
    };
  },
  methods: {
    openBadge(item) {
      this.nama = item.nama_lengkap;
      this.badges = item.badge;
      this.dialogBadge = true;
    },
    closeDialog() {
      this.dialogBadge = false;
    },
  },
};
</script>

<style scoped>
.gold {
  border-left: gold 2px solid;
}
.temp-leader {
  width: 100%;
}
.head {
  padding: 10px;
  border-radius: 8px 8px 0 0;
  color: white;
}
</style>
