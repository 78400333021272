import gql from "graphql-tag";
//GET EVENT
export const GET_EVENT = gql`
  query($page: Int!, $first: Int!, $search: String) {
    allPelatihan(page: $page, first: $first, search: $search) {
      data {
        id
        nama
        modul_id
        modul {
          id
          nama_modul
          tipe
          modul_kasus {
            id
          }
          modul_tugas {
            id
          }
          berita {
            id
          }
        }
        grup {
          id
          nama
          user {
            id
            nama_lengkap
            email
          }
        }
      }
      paginatorInfo {
        currentPage
        total
      }
    }
  }
`;

//GET EVENT by teacher
export const GET_EVENT_BY_TEACHER = gql`
  query($teacher_id: Int!) {
    pelatihanByTeacher(teacher_id: $teacher_id) {
      id
      nama
      modul_id
      modul {
        id
        nama_modul
        tipe
        modul_kasus {
          id
        }
        modul_tugas {
          id
        }
        berita {
          id
        }
      }
      grup {
        id
        nama
        user {
          id
          nama_lengkap
          email
        }
      }
    }
  }
`;

//GET EVENT BY ID
export const EVENT_BY_ID = gql`
  query($id: ID!) {
    pelatihanById(id: $id) {
      id
      nama
      start_date_individu
      end_date_individu
      start_date_kelompok
      end_date_kelompok
      modul_id
      game
      modul {
        id
        nama_modul
        tipe
        modul_kasus {
          id
        }
        modul_tugas {
          id
        }
        berita {
          id
        }
      }
      grup {
        id
        nama
        user {
          id
          nama_lengkap
          email
        }
      }
    }
  }
`;

//CREATE EVENT
export const CREATE_EVENT = gql`
  mutation($nama: String!, $teacher_id: Int!) {
    createPelatihan(nama: $nama, teacher_id: $teacher_id) {
      id
      nama
    }
  }
`;

//DELETE EVENT
export const DELETE_EVENT = gql`
  mutation($id: ID!) {
    deletePelatihan(id: $id) {
      id
    }
  }
`;
//UPDATE EVENT (DATE)
export const UPDATE_EVENT = gql`
  mutation(
    $id: ID!
    $nama: String
    $game: String
    $start_date_individu: Date
    $end_date_individu: Date
    $start_date_kelompok: Date
    $end_date_kelompok: Date
    $modul_id: Int
  ) {
    updatePelatihan(
      id: $id
      nama: $nama
      game: $game
      start_date_individu: $start_date_individu
      end_date_individu: $end_date_individu
      start_date_kelompok: $start_date_kelompok
      end_date_kelompok: $end_date_kelompok
      modul_id: $modul_id
    ) {
      id
      nama
      game
      start_date_individu
      end_date_individu
      start_date_kelompok
      end_date_kelompok
      modul_id
      modul {
        id
        tipe
      }
    }
  }
`;

// ---------------------------------------------------------------------------------------------------------------------------------------

//CREATE GROUP
export const CREATE_GROUP = gql`
  mutation(
    $pelatihan_id: Int!
    $nama: String!
    $teacher_id: Int!
    $mentor_id: Int
  ) {
    createGrup(
      nama: $nama
      teacher_id: $teacher_id
      pelatihan_id: $pelatihan_id
      mentor_id: $mentor_id
    ) {
      id
      nama
      mentor {
        id
      }
    }
  }
`;

// GET GROUP BY ID
export const GROUP_BY_ID = gql`
  query($id: ID!) {
    grupById(id: $id) {
      id
      nama
      pelatihan_id
      teacher_id
      user {
        id
        name
        nama_lengkap
        email
        tipe_avatar
        student {
          id
          tempat_lahir
          tanggal_lahir
          no_peserta
          nik
          nip
          golongan
          pangkat
          jabatan
          unit_kerja
          instansi
          jenis_kelamin
          agama
          alamat_tinggal
          alamat_ktp
        }
      }
      mentor {
        id
        nama_lengkap
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation($id: ID!) {
    deleteGrup(id: $id) {
      id
    }
  }
`;
// ---------------------------------------------------------------------------------------------------------------------------------------
//GET EVENT
export const COUNT_EVENT = gql`
  query($page: Int, $first: Int!, $search: String) {
    allPelatihan(page: $page, first: $first, search: $search) {
      data {
        id
        nama
      }
      paginatorInfo {
        total
      }
    }
  }
`;
//GET ALL GAME
export const ALL_GAME = gql`
  query {
    allGame {
      id
      nama
      path
    }
  }
`;
