<template>
  <v-row>
    <v-dialog v-model="helpDashboard" persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Dashboard</h3>
        </v-toolbar>
        <div class="py-3 px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/leaderboard-header.jpg"
              width="100%"
              class="mr-3"
              alt="pic"
            />
          </section>

          <div>
            <section class="mb-1">
              <h3>
                1. Merupakan informasi yang menunjukan hari berjalannya sebuah
                pelatihan.
              </h3>
            </section>
            <section class="mb-1">
              <h3>2. PMenunjukan fase pelatihan yang sedang dijalani.</h3>
            </section>
            <section class="mb-1">
              <h3>
                3. Leaderboard individu yang menampilkan nama, kelompok, poin,
                dan badge lima peserta teratas yang mengikuti pelatihan.
              </h3>
            </section>
            <section class="mb-1">
              <h3>
                4.Informasi kapan berakhirnya fase pelatihan yang sedang
                berlangsung.
              </h3>
            </section>
            <section>
              <h3>
                5.Leaderboard kelompok menunjukan lima kelompok teratas yang
                mengikuti pelatihan dan memiliki poin akumulasi anggota
                tertinggi.
              </h3>
            </section>
          </div>
        </div>
        <div class="py-3 px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/feeds.jpg"
              width="100%"
              class="mr-3"
              alt="pic"
            />
          </section>
          <section>
            <h3>Feeds menampilkan aktivitas semua peserta pelatihan</h3>
          </section>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "helpDashboardt",
  props: ["helpDashboard"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
