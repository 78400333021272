<template>
  <div class="container-general">
    <div class="temp-general">
      <section class="banner d-flex">
        <h1 class="white--text">Ringkasan</h1>
      </section>
      <section class="ringkasan">
        <v-card
          class="card-ringkasan purple accent-4 d-flex justify-space-around align-center pa-3"
          :elevation="3"
        >
          <v-icon dark x-large> mdi-account </v-icon>
          <h1 class="white--text">Total Akun Siswa</h1>
          <h1 class="white--text">{{ student }}</h1>
        </v-card>
        <v-card
          class="card-ringkasan blue darken-1 d-flex justify-space-around align-center pa-3"
          :elevation="3"
        >
          <v-icon dark x-large> mdi-account-child </v-icon>
          <h1 class="white--text">Total Akun Coach</h1>
          <h1 class="white--text">{{ mentor }}</h1>
        </v-card>
        <v-card
          class="card-ringkasan pink accent-3 d-flex justify-space-around align-center pa-3"
          :elevation="3"
        >
          <v-icon dark x-large> mdi-account-group </v-icon>
          <h1 class="white--text">Total Kelompok</h1>
          <h1 class="white--text">{{ group }}</h1>
        </v-card>
      </section>
      <section class="banner d-flex">
        <h1 class="white--text">Feed</h1>
      </section>

      <section class="tl-general">
        <!-- <div class="post-status-temp d-flex">
          <v-textarea
            solo
            label="Bagikan Sesuatu Kepada Yang Lain"
            dense
            row-height="7"
            :auto-grow="true"
            hide-details
            flat
          ></v-textarea>
          <span class="d-flex ml-3">
            <v-btn dark fab small depressed color="indigo">
              <v-icon dark small> mdi-send small </v-icon>
            </v-btn>
          </span>
        </div> -->
        <div class="temp-tl">
          <v-progress-linear
            :active="!feeds"
            :indeterminate="!feeds"
            absolute
            top
            color="orange"
          ></v-progress-linear>
          <div class="temp-tl" v-if="feeds">
            <div
              class="post-status-temp mt-4"
              v-for="(log, i) in feeds"
              :key="i"
            >
              <section class="d-flex align-center">
                <v-avatar size="40" color="indigo">
                  <span class="white--text font-weight-bold">{{
                    log.nama_lengkap[0].toUpperCase()
                  }}</span>
                </v-avatar>
                <h3 class="mx-3">{{ log.nama_lengkap }}</h3>
              </section>
              <section class="my-2 d-flex">
                <div style="width: 50px"></div>
                <v-card class="pa-3" style="width: 90%">
                  <span v-html="log.keterangan"></span>
                </v-card>
              </section>
              <footer class="d-flex">
                <div style="width: 50px"></div>
                <div>
                  <v-icon color="grey" small>mdi-clock-outline</v-icon>
                  <small class="grey--text ml-2"
                    >{{ moment(log.created_at).format("HH:mm") }} |
                    {{ moment(log.created_at).format("DD MMMM YYYY") }}</small
                  >
                </div>
              </footer>
            </div>
            <div v-if="feeds" v-observe-visibility="handleScroll"></div>
          </div>
        </div>
      </section>
      <div class="d-flex justify-center" v-if="!feeds">
        <v-progress-circular
          indeterminate
          size="30"
          color="indigo"
          class="mr-2 ml-2"
        ></v-progress-circular>
        memuat
      </div>
    </div>
  </div>
</template>

<script>
import { COUNT_STUDENT } from "../../graphql/Student";
import { COUNT_MENTOR } from "../../graphql/Mentor";
import { GET_GROUP } from "../../graphql/Group";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "General",
  apollo: {
    scopeStudent: {
      query: COUNT_STUDENT,
      result(data) {
        console.log(data.data.scopeStudent.data);
        this.student = data.data.scopeStudent.paginatorInfo.total;
      },
      variables() {
        return { page: 1, first: 10 };
      },
      error(err) {
        console.log(err);
      }
    },
    grupByTeacher: {
      variables() {
        return { teacher_id: this.idTeacher };
      },
      query: GET_GROUP,
      result(data) {
        // console.log(data);
        this.group = data.data.grupByTeacher.length;
      }
    },
    scopeMentor: {
      query: COUNT_MENTOR,
      variables() {
        return { page: 1, first: 10 };
      },
      result(data) {
        this.mentor = data.data.scopeMentor.paginatorInfo.total;
      },
      error(err) {
        console.log(err);
      }
    }
  },
  computed: {
    ...mapState(["feeds", "lastLog"]),
    idTeacher() {
      return localStorage.getItem("id");
    }
  },
  data() {
    return {
      student: null,
      group: null,
      mentor: null,
      perpage: 5,
      page: 1,
      moment: moment
    };
  },
  mounted() {
    this.$store.dispatch("theFeeds", {
      per_page: this.perpage,
      page: this.page
    });
  },
  methods: {
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.page <= this.lastLog) {
          this.page++;
          console.log(this.page);
          this.$store.dispatch("theFeeds", {
            per_page: this.perpage,
            page: this.page
          });
        }
      }
    }
  }
};
</script>

<style scoped>
nav {
  padding: 20px 10px;
  background: #f4f6f9;
}
.ringkasan {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 0;
}
.card-ringkasan {
  width: calc(100% / 3);
  height: 100px;
  margin: 10px;
  border-radius: 10px;
}
.leaderboard {
  width: 50%;
}
.container-general {
  width: 100%;
  background: #f4f6f9;
  padding: 10px;
  min-height: 90vh;
}
.banner {
  background: #1a237e;
  border-radius: 8px;
  padding: 10px;
}
.tl-general {
  width: 100%;
  padding: 10px;
}
.post-status-temp {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.temp-tl {
  padding: 0 20px 0 20px;
}
.dva {
  object-fit: cover;
}
.post-input {
  border: none;
  background: #f4f6f9;
  width: 80%;
  border-radius: 8px;
  outline: none;
  padding: 10px;
}
</style>
