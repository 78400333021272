<template> 
  <div class="container-general">
    <div class="temp-general">
      <section class="banner d-flex">
        <h1 class="white--text">Feed</h1>
      </section>
      <div v-if="!feeds">Belum ada feeds</div>
      <section class="tl-general">
        <div class="temp-tl">
          <v-progress-linear
            :active="!feeds"
            :indeterminate="!feeds"
            absolute
            top
            color="orange"
          ></v-progress-linear>
          <div class="temp-tl" v-if="feeds">
            <div
              class="post-status-temp mt-4"
              v-for="(log, i) in feeds"
              :key="i"
            >
              <section class="d-flex align-center">
                <v-avatar size="40" color="indigo">
                  <span class="white--text font-weight-bold">{{
                    log.nama_lengkap[0].toUpperCase()
                  }}</span>
                </v-avatar>
                <h3 class="mx-3">{{ log.nama_lengkap }}</h3>
              </section>
              <section class="my-2 d-flex">
                <div style="width: 50px"></div>
                <v-card class="pa-3" style="width: 90%">
                  <span v-html="log.keterangan"></span>
                </v-card>
              </section>
              <footer class="d-flex">
                <div style="width: 50px"></div>
                <div>
                  <v-icon color="grey" small>mdi-clock-outline</v-icon>
                  <small class="grey--text ml-2"
                    >{{ moment(log.created_at).format("HH:mm") }} |
                    {{ moment(log.created_at).format("DD MMMM YYYY") }}</small
                  >
                </div>
              </footer>
            </div>
            <div v-if="feeds" v-observe-visibility="handleScroll"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "General",

  computed: {
    ...mapState(["feeds", "lastLog"])
  },
  data() {
    return {
      student: null,
      group: null,
      mentor: null,
      perpage: 5,
      page: 1,
      moment: moment
    };
  },
  mounted() {
    this.$store.dispatch("theFeeds", {
      per_page: this.perpage,
      page: this.page
    });
  },
  methods: {
    handleScroll(isVisible) {
      if (isVisible) {
        if (this.page <= this.lastLog) {
          this.page++;
          console.log(this.page);
          this.$store.dispatch("theFeeds", {
            per_page: this.perpage,
            page: this.page
          });
        }
      }
    }
  }
};
</script>

<style scoped>
nav {
  padding: 20px 10px;
  background: #f4f6f9;
}
.ringkasan {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 0;
}
.card-ringkasan {
  width: calc(100% / 3);
  height: 100px;
  margin: 10px;
  border-radius: 10px;
}
.leaderboard {
  width: 50%;
}
.container-general {
  width: 100%;
  background: #f4f6f9;
  padding: 10px;
  min-height: 90vh;
}
.banner {
  background: #1a237e;
  border-radius: 8px;
  padding: 10px;
}
.tl-general {
  width: 100%;
  padding: 10px;
}
.post-status-temp {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.temp-tl {
  padding: 0 20px 0 20px;
}
.dva {
  object-fit: cover;
}
.post-input {
  border: none;
  background: #f4f6f9;
  width: 80%;
  border-radius: 8px;
  outline: none;
  padding: 10px;
}
</style>
